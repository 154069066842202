import {get} from '@/lib/axios'

export default {
    async getDealersForAgentUserProductOrders() {
        return get('/dealers/agent-user/for-product-orders');
    },
    async getSubdivisionsForAgentUserProductOrders() {
        return get('/subdivisions/agent-user/for-product-orders');
    },
    async getSubdivisionUsersForAgentUserProductOrders() {
        return get('/subdivision-users/agent-user/for-product-orders');
    },
    async getDealerProductOrderStatistics(page = null, pageSize = null, query = '') {
        let url = '/orders/agent-user/dealer-order-statistics';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async getDealerProductOrderStatisticsReport(query = '') {
        let url = '/orders/agent-user/dealer-order-statistics-report';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {}, {responseType: 'blob'});
    },
    async getSubdivisionProductOrderStatistics(page = null, pageSize = null, query = '') {
        let url = '/orders/agent-user/subdivision-order-statistics';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async getSubdivisionProductOrderStatisticsReport(query = '') {
        let url = '/orders/agent-user/subdivision-order-statistics-report';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {}, {responseType: 'blob'});
    },
}