import {post, get, patch} from '@/lib/axios';

const account = {
    async login(credentials) {
        return post('/account/authenticate', credentials);
    },
    async loginAsSubdivisionUser(id) {
        return get(`/account/login-as-subdivision-user/${id}`);
    },
    async loginAsDealer(id) {
        return get(`/account/login-as-dealer/${id}`);
    },
    async changePassword(credentials) {
        return post('account/change-password', credentials)
    }
};

export {account as default}
