import { get, put, post, remove } from "@/lib/axios";

const orders = {
  async getDealerProductOrders(page = null, pageSize = null, query = "") {
    let url = "/orders/dealer";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getDealerEducationOrders(page = null, pageSize = null, query = "") {
    let url = "/agent-education/get-course-request-orders";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getProductOrdersGeneralReport(query = "") {
    let url = "/orders/report";

    if (query) {
      url += `?${query}`;
    }

    return get(url, {}, { responseType: "blob" });
  },
  async getProductOrders(query = "", type = "subdivision") {
    let url = `/catalog/${type}/products-for-report`;

    if (query) {
      url += `?${query}`;
    }

    return get(url, {}, { responseType: "blob" });
  },
  async getEducationOrdersGeneralReport(query = "") {
    let url = "/course-order/statistics-report";

    if (query) {
      url += `?${query}`;
    }

    return get(url, {}, { responseType: "blob" });
  },
  async getDealerProductOrdersProductReport(data) {
    return post("/orders/dealer/product-report", data, {
      responseType: "blob",
    });
  },
  async getDealerEducationOrdersProductReport(data) {
    return post("course-orders/area-object-report", data, {
      responseType: "blob",
    });
  },
  async getSubdivisionProductOrdersProductReport(data) {
    return post("/orders/subdivision/product-report", data, {
      responseType: "blob",
    });
  },
  async getVisitOrders(page = null, pageSize = null, query = "") {
    let url = "/visit-orders";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getVisitsNewOrders(page = null, pageSize = null, query = "") {
    let url = "/agent-visit/get-visit-orders";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getVisitOrdersReport(query = "") {
    let url = "/visit-orders/report";

    if (query) {
      url += `?${query}`;
    }

    return get(url, {}, { responseType: "blob" });
  },
  async getNewVisitOrdersReport(query = "") {
    let url = "/visit-order/get-visit-orders-statistics-report";

    if (query) {
      url += `?${query}`;
    }

    return get(url, {}, { responseType: "blob" });
  },
  async getVisitOrdersAreaObjectReport(data) {
    return post("/visit-orders/area-object-report", data, {
      responseType: "blob",
    });
  },
  async getVisitOrderFilters() {
    return get(`/visit-orders/filter`);
  },
  async cancelDealerProductOrder(id) {
    return put(`/orders/cancel-dealer-order/${id}`);
  },
  async cancelDealerEducationOrder(id) {
    return put(`/course-orders/cancel-dealer-order/${id}`);
  },
  async cancelSubdivisionProductOrder(id) {
    return put(`/orders/cancel-subdivision-order/${id}`);
  },
  async getSubdivisionProductOrderStatistics(
      page = null,
      pageSize = null,
      query = ""
  ) {
    let url = "/orders/subdivision-statistics";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getSubdivisionProductOrderStatisticsReport(query = "") {
    let url = "/orders/subdivision-statistics-report";

    if (query) {
      url += `?${query}`;
    }

    return get(url, {}, { responseType: "blob" });
  },
  async getAgentUserDealerProductOrders(
      page = null,
      pageSize = null,
      query = ""
  ) {
    let url = "/orders/agent-user/dealers";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getAgentUserSubdivisionProductOrders(
      page = null,
      pageSize = null,
      query = ""
  ) {
    let url = "/orders/agent-user/subdivisions";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getAvailableStatusesToChange(id) {
    return get(`/orders/available-statuses-to-change/${id}`);
  },
  async getAvailableStatusesToChangeVisit(id) {
    return get(`/visits/available-statuses-to-change/${id}`);
  },
  async getAvailableStatusesToChangeEducation(id) {
    return get(`/course-orders/available-statuses-to-change/${id}`);
  },
  async changeDealerOrderStatus(id, status) {
    return put(`/orders/change-dealer-order-status/${id}`, { status });
  },
  async changeSubdivisionOrderStatus(id, status) {
    return put(`/orders/change-subdivision-order-status/${id}`, { status });
  },
  async changeEducationOrderStatus(id, status) {
    return put(`/course-order/change-dealer-order-status/${id}`, { status });
  },
  async changeVisitOrderStatus(id, status) {
    return put(`/visits/change-dealer-order-status/${id}`, { status });
  },
  async changeVisitCancelOrderStatus(id, status, refuse_reason) {
    return put(`/visits/change-dealer-order-status/${id}`, { status, refuse_reason });
  },
  async deleteProductOrderPosition(id, data, type = "dealer") {
    return post(`/orders/agent-user/${type}/delete-positions/${id}`, data);
  },
  async deleteVisitOrderPosition(id, data, type = "dealer") {
    return post(`/orders/agent-user/${type}/delete-positions/${id}`, data);
  },
  async getExportRequests(query = '') {
    let url = '/orders/agent-user/subdivisions-report'
    if (query) {
      url += `?${query}`;
    }
    return get(url, {}, { responseType: "blob" })

  }
};

export { orders as default };
