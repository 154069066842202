export const STATUS_ACCEPTED = 1;
export const STATUS_PROCESSING = 2;
export const STATUS_TRANSFERED_T0_DELIVERY = 3;
export const STATUS_DELIVERED = 4;
export const STATUS_CANCELLED = 5;
export const STATUS_PAYED = 6;

export default [
    { key: STATUS_ACCEPTED, name: 'Принят'},
    { key: STATUS_PROCESSING, name: 'В работе'},
    { key: STATUS_PAYED, name: 'Оплачен'},
    { key: STATUS_TRANSFERED_T0_DELIVERY, name: 'Передан в доставку'},
    { key: STATUS_DELIVERED, name: 'Доставлен'},
    { key: STATUS_CANCELLED, name: 'Отменён'},
];
