export const STATUS_ACCEPTED = 1;
export const STATUS_CONFIRMED = 2;
export const STATUS_PROCESSING = 3;
export const STATUS_CANCELLED= 4;
export const STATUS_DONE = 5;

export default [
    { key: STATUS_ACCEPTED, name: 'Принята'},
    { key: STATUS_CONFIRMED, name: 'Подтверждена'},
    { key: STATUS_PROCESSING, name: 'В работе'},
    { key: STATUS_CANCELLED, name: 'Отменена'},
    { key: STATUS_DONE, name: 'Выполнена'},
];