import {post, get, put, remove} from '@/lib/axios';

const structural_subdivisions = {
    async getSubdivisions(page = null, pageSize = null, query = '') {
        let url = '/subdivisions';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async createSubdivision(data) {
        return post('/subdivisions', data);
    },
    async updateSubdivision(id, data) {
        return put(`/subdivisions/${id}`, data);
    },
    async deleteSubdivision(id) {
        return remove(`/subdivisions/${id}`)
    },
    async subdivisionsShort() {
        return get(`/subdivisions/short`);
    },
    async subdivisionsWithProducts() {
        return get(`/subdivisions/with-products`);
    },
    async subdivisionsProductsShort(subdivision_id) {
        return get(`/subdivisions/products/short/${subdivision_id}`);
    },
    async subdivisionsForUsers() {
        return get(`/subdivisions/short-for-users`);
    },
    async categoriesShort(agency_id = null) {
        return get('/catalog/subdivision/categories/short', {agency_id});
    },
    async sectionsShort() {
        return get('/catalog/subdivision/sections/short');
    },
    async getProducts(page = null, pageSize = null, query = '') {
        let url = '/subdivisions/products';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async createProduct(data) {
        return post('/subdivisions/products', data);
    },
    async updateProduct(id, data) {
        return put(`/subdivisions/products/${id}`, data);
    },
    async deleteProduct(ids) {
        return remove(`/subdivisions/products`, { data: { ids } });
    },
    async uploadProducts(data) {
        return post(`/subdivisions/products/upload`, data);
    },
    async moveBalance(id, data) {
        return put(`/subdivisions/products/${id}/move-balance`, data);
    },
    async getUsers(page = null, pageSize = null, query = '') {
        let url = '/subdivisions/users';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async getUsersShort() {
        return get('/subdivisions/users/short');
    },
    async createUser(data) {
        return post('/subdivisions/users', data);
    },
    async updateUser(id, data) {
        return put(`/subdivisions/users/${id}`, data);
    },
    async deleteUser(id) {
        return remove(`/subdivisions/users/${id}`)
    },
    async toggleUserProperty(id, propertyName) {
        return put(`/subdivisions/users/${id}/toggle/${propertyName}`);
    },
    async resetUserPassword(id) {
        return put(`/subdivisions/users/${id}/reset-password`);
    },
    async loginAsSubdivisionUser(id) {
        return get(`/subdivisions/users/${id}/login-as`);
    },
    async subdivisionUsersActive() {
        return get('/subdivisions/users/active').then(response => {
            return response.data;
        });
    },
    async subdivisionProductsReport(data) {
        return post('/subdivisions/balance-report', data, {responseType: 'blob'});
    }
};

export {structural_subdivisions as default}
