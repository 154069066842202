export const STATUS_ACCEPTED = 1;
export const STATUS_CONFIRMED = 2;
export const STATUS_PROCESSING = 3;
export const STATUS_CANCELLED = 4;
export const STATUS_DONE = 5;

export default [
  { key: STATUS_ACCEPTED, name: "Принят" },
  { key: STATUS_CONFIRMED, name: "Подтвержден" },
  { key: STATUS_PROCESSING, name: "В работе" },
  { key: STATUS_CANCELLED, name: "Отменен" },
  { key: STATUS_DONE, name: "Выполнен" },
];
