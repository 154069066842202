import {post, get} from '@/lib/axios';

const mailings = {
    async getMailings(page = null, pageSize = null, query = '') {
        let url = '/mailings';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async createMailing(data) {
        return post('/mailings', data);
    },
}

export {mailings as default}
