import api from '@/api';

export default {
    namespaced: true,
    state: {
        categoriesListShort: [{"id":"13930EF5-5BCE-403E-81A9-5FECCB138FA5","name":"Прочее","section_name":"ПОЛИГРАФИЯ"}, {"id":"13930EF5-5BCE-403E-81A9-5FECCB138FA5","name":"Прочее","section_name":"ПОЛИГРАФИЯ"}, {"id":"13930EF5-5BCE-403E-81A9-5FECCB138FA5","name":"Прочее","section_name":"ПОЛИГРАФИЯ"}],
        sectionListShort: [],
        subdivisionListShort: [],
        subdivisionWithProductsList: [],
        subdivisionUsersListShort: [],
        subdivisionForUsersList: [
            {"id":"d60f24da-fc8f-447c-97cf-5471f5e91fea","name":"Филиал в Тюмени"},
            {"id":"cd6dddb7-30fe-4a9f-b676-5eb7dfe9846e","name":"паавпвпвапв"},
            {"id":"1e418b74-0b88-4d6b-9b1b-7f1bfa0eba7f","name":"ТПП ООО «ЛЛК-ИНТЕРНЕШНЛ» г. Пермь"}
        ]
    },
    getters: {
        categoriesListShort: ({categoriesListShort}) => categoriesListShort,
        sectionListShort: ({sectionListShort}) => sectionListShort,
        subdivisionListShort: ({subdivisionListShort}) => subdivisionListShort,
        subdivisionWithProductsList: ({subdivisionWithProductsList}) => subdivisionWithProductsList,
        subdivisionForUsersList: ({subdivisionForUsersList}) => subdivisionForUsersList,
        subdivisionUsersListShort: ({subdivisionUsersListShort}) => subdivisionUsersListShort
    },
    mutations: {
        SET_CATEGORIES_LIST_SHORT(state, categories) {
            state.categoriesListShort = categories;
        },
        SET_SECTION_LIST_SHORT(state, sectionListShort) {
            state.sectionListShort = sectionListShort;
        },
        SET_SUBDIVISION_LIST_SHORT(state, subdivisionListShort) {
            state.subdivisionListShort = subdivisionListShort;
        },
        SET_SUBDIVISION_WITH_PRODUCTS_LIST(state, subdivisionWithProductsList) {
            state.subdivisionWithProductsList = subdivisionWithProductsList;
        },
        SET_SUBDIVISION_FOR_USERS_LIST(state, subdivisionForUsersList) {
            state.subdivisionForUsersList = subdivisionForUsersList;
        },
        SET_SUBDIVISION_USERS_LIST_SHORT(state, subdivisionUsersListShort) {
            state.subdivisionUsersListShort = subdivisionUsersListShort;
        }
    },
    actions: {
        async fetchCategoryListShort({commit}, agency_id = null) {
            try {
                const {data: {data: {items}}} = await api.structural_subdivisions.categoriesShort(agency_id);

                commit('SET_CATEGORIES_LIST_SHORT', items);
            } catch (e) {}
        },
        async fetchSectionListShort({commit}) {
            try {
                const {data: {data: {items}}} = await api.structural_subdivisions.sectionsShort();

                commit('SET_SECTION_LIST_SHORT', items);
            } catch (e) {}
        },
        async fetchSubdivisionListShort({commit}) {
            try {
                const {data: {data: {items}}} = await api.structural_subdivisions.subdivisionsShort();

                commit('SET_SUBDIVISION_LIST_SHORT', items);
            } catch (e) {}
        },
        async fetchSubdivisionWithProductsList({commit}) {
            try {
                const {data: {data: {items}}} = await api.structural_subdivisions.subdivisionsWithProducts();

                commit('SET_SUBDIVISION_WITH_PRODUCTS_LIST', items);
            } catch (e) {}
        },
        async fetchSubdivisionForUsersList({commit}) {
            try {
                const {data: {data: {items}}} = await api.structural_subdivisions.subdivisionsForUsers();

                commit('SET_SUBDIVISION_FOR_USERS_LIST', items);
            } catch (e) {}
        },
        async fetchSubdivisionUsersListShort({commit}) {
            try {
                const {data: {data: {items}}} = await api.structural_subdivisions.getUsersShort();

                commit('SET_SUBDIVISION_USERS_LIST_SHORT', items);
            } catch (e) {
                console.warn(e);
            }
        },
    }
};
