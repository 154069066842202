import OrderStatuses from "@/config/order_statuses";
import EducationStatuses from "@/config/education_order_statuses";
import VisitStatuses from "@/config/visit_order_statuses";
import DeliveryTypes from "@/config/delivery_types";

export default {
  namespaced: true,
  getters: {
    getProductOrderStatusNameByKey: () => (key) => {
      const status = OrderStatuses.find((item) => item.key === key);
      return status ? status.name : null;
    },
    getEducationOrderStatusNameByKey: () => (key) => {
      const status = EducationStatuses.find((item) => item.key === key);
      return status ? status.name : null;
    },
    getVisitOrderStatusNameByKey: () => (key) => {
      const status = VisitStatuses.find((item) => item.key === key);
      return status ? status.name : null;
    },
    getDeliveryTypeNameByKey: () => (key) => {
      const type = DeliveryTypes.find((item) => item.key === key);
      return type ? type.name : null;
    },
  },
};
