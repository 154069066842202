import {post, get, put, remove} from '@/lib/axios';

const dealers = {
    async getDealers(page = null, pageSize = null, query = '') {
        let url = '/dealers';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async dealersShort() {
        return get('/dealers/short');
    },
    async createDealer(data) {
        return post('/dealers', data);
    },
    async updateDealer(id, data) {
        return put(`/dealers/${id}`, data);
    },
    async deleteDealer(id) {
        return remove(`/dealers/${id}`)
    },
    async toggleDealerProperty(id, propertyName) {
        return put(`/dealers/${id}/toggle/${propertyName}`);
    },
    async resetDealerPassword(id) {
        return get(`/dealers/${id}/reset-password`);
    },
    async loginAsDealer(id) {
        return get(`/dealers/${id}/login-as`);
    },
    async uploadBonus(data) {
        return post(`/dealers/upload-bonus`, data, {
            timeout: 15000000000,
        });
    },
    async downloadDealerBalance(date) {
        return get(`/dealers/balance-report`, {date}, {responseType: 'blob'});
    },
    async dealersActive() {
        return get('/dealers/active').then(response => {
            return response.data;
        });
    },
}

export {dealers as default}
