import { extend } from 'vee-validate';
import { required, email, min_value, max_value, min, max, numeric, integer, alpha_spaces, alpha, regex} from 'vee-validate/dist/rules';

// No message specified.
extend('email', email);

// Override the default message.
extend('required', {
    ...required,
    message: 'Данное поле обязательно'
});

extend('numeric', numeric)
extend('min_value', min_value);
extend('max_value', max_value);
extend('max', max);
extend('integer', integer);
extend('alpha_spaces', alpha_spaces);
extend('alpha', alpha);
extend('regex', regex);

extend('min', {
    ...min,
    message: 'Данное поле должно содержать не менее {length} символов'
});
