import {post, get, put, remove} from '@/lib/axios';

const agencies = {
    async getAgencies(page = null, pageSize = null, query = '') {
        let url = '/agencies';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async createAgency(data) {
        return post('/agencies', data);
    },
    async updateAgency(id, data) {
      return put(`/agencies/${id}`, data);
    },
    async deleteAgency(id) {
        return remove(`/agencies/${id}`)
    },
    async toggleAgencyProperty(id, propertyName) {
        return put(`/agencies/${id}/toggle/${propertyName}`);
    },
    async agenciesShort() {
        return get('/agencies/short');
    },
    async getAgents(page = null, pageSize = null, query = '') {
        let url = '/agents';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async createAgent(data) {
        return post('/agents', data);
    },
    async updateAgent(id, data) {
        return put(`/agents/${id}`, data);
    },
    async deleteAgent(id) {
        return remove(`/agents/${id}`)
    },
    async resetAgentPassword(id) {
        return get(`/agents/${id}/reset-password`);
    },
    async loginAsAgent(id) {
        return get(`/agents/${id}/login-as`);
    },
    async toggleAgentProperty(id, propertyName) {
        return put(`/agents/${id}/toggle/${propertyName}`);
    }
};

export {agencies as default}