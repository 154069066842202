export const Admin = 1;
export const Dealer = 2;
export const AgentUser = 3;
export const AgentOrganizer = 4;
export const StructuralSubdivisionUser = 5;
export const AgentEducation = 6;
export const AgentWriteDown = 7;

export default [
  Admin,
  Dealer,
  AgentUser,
  AgentOrganizer,
  StructuralSubdivisionUser,
  AgentEducation,
  AgentWriteDown,
];
