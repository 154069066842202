import router from "./index";
import {
  Admin,
  AgentOrganizer,
  AgentUser,
  AgentEducation, AgentWriteDown,
} from "@/config/roles";
import {
  DEFAULT_ADMIN_URL,
  DEFAULT_AGENT_USER_URL,
  DEFAULT_AGENT_ORGANIZER_URL,
  EDUCATION_AGENT_ORGANIZER_URL,
  WRITE_DOWN_AGENT_ORGANIZER_URL,
} from "@/config/default_urls";

export const redirectToProperIndex = (role) => {
  switch (parseInt(role)) {
    case Admin:
      router.push(DEFAULT_ADMIN_URL);
      break;
    case AgentUser:
      router.push(DEFAULT_AGENT_USER_URL);
      break;
    case AgentOrganizer:
      router.push(DEFAULT_AGENT_ORGANIZER_URL);
      break;
    case AgentEducation:
      router.push(EDUCATION_AGENT_ORGANIZER_URL);
      break;
    case AgentWriteDown:
      router.push(WRITE_DOWN_AGENT_ORGANIZER_URL);
      break;
  }
};
