import 'core-js/stable'
import "regenerator-runtime/runtime"
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/index'
import './lib/validation'
import './lib/toasted'
import './lib/swal2'
import './lib/moment'
import './lib/fragment'
import './lib/filters'
import './lib/mixins'
import '@progress/kendo-theme-default/dist/all.css'
import { Grid } from '@progress/kendo-vue-grid'
import Vuetify from 'vuetify/'
import 'vuetify/dist/vuetify.min.css'
import contenteditable from 'vue-contenteditable'
import { VueMaskDirective } from 'v-mask'

Vue.directive('mask', VueMaskDirective);

Vue.use(Vuetify)
Vue.use(contenteditable)

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.component('Grid', Grid)
Vue.prototype.$log = console.log.bind(console)

new Vue({
    el: '#app',
    router,
    store,
    icons,
    vuetify: new Vuetify({
        icons: {
            iconfont: 'mdi', // default - only for display purposes
        },
    }),
    template: '<App/>',
    components: {
        App
    }
})
