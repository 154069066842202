import Vue from "vue";

Vue.filter('fractionSizeSpace', function (value) {
    // переводим строку число в строку
    let str = value.toString();

    // если число дробное, то берём часть до точки
    let dotIndex = str.indexOf('.');
    var searchStr = dotIndex == -1 ? str : str.slice(0, dotIndex);

    // делим по разрядам
    let strLength = searchStr.length;
    var result = "";
    for (let index = 0; index < strLength; index++) {
        if ((strLength - index) % 3 == 0)
            result += ' ';
        result += searchStr[index];
    }

    // прибавляем дробную часть
    if (dotIndex != -1)
        result += str.slice(dotIndex, str.length)

    return result;
})