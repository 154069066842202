import api from '@/api';

export default {
    namespaced: true,
    state: {
        dealersListShort: []
    },
    getters: {
        dealersListShort: ({dealersListShort}) => dealersListShort
    },
    mutations: {
        SET_DEALERS_LIST_SHORT(state, dealersShort) {
            state.dealersListShort = dealersShort;
        }
    },
    actions: {
        async fetchDealerListShort({commit}) {
            try {
                const {data: {data: {items}}} = await api.dealers.dealersShort();
                commit('SET_DEALERS_LIST_SHORT', items);
            } catch (e) {
                console.warn(e);
            }
        }
    }
};
