import {get, put} from "@/lib/axios";

const travels = {
  async visits(page = null, pageSize = null, query = '') {
    let url = `/visits`;
    
    if (query) {
      url += `?${query}`;
    }
    
    return get(url, {page, pageSize});
  },
  async updateVisit(id, data) {
    return put(`/visits/${id}`, data)
  }
}

export default travels
