import {get, put} from '@/lib/axios';

const system_params = {
    async systemParams(page = null, pageSize = null, query = '') {
        let url = `/built-in-parameters`;

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async updateSystemParam(id, data) {
        return put(`/built-in-parameters/${id}`, data)
    }
}


export {system_params as default}