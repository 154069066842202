import api from '@/api';
import dealers from "../../api/entities/dealers";

export default {
    namespaced: true,
    state: {
        categoriesListShort: [],
        sectionListShort: [],
        categoriesWithProductsList: []
    },
    getters: {
        categoriesListShort: ({categoriesListShort}) => categoriesListShort,
        sectionListShort: ({sectionListShort}) => sectionListShort,
        categoriesWithProductsList: ({categoriesWithProductsList}) => categoriesWithProductsList,
    },
    mutations: {
        SET_CATEGORIES_LIST_SHORT(state, categories) {
            state.categoriesListShort = categories;
        },
        SET_SECTION_LIST_SHORT(state, sectionListShort) {
            state.sectionListShort = sectionListShort;
        },
        SET_CATEGORIES_WITH_PRODUCTS_LIST(state, categoriesWithProductsList) {
            state.categoriesWithProductsList = categoriesWithProductsList;
        }
    },
    actions: {
        async fetchCategoryListShort({commit}, type = 'dealer') {
            try {
                const {data: {data: {items}}} = await api.catalog.categoriesShort(type);

                commit('SET_CATEGORIES_LIST_SHORT', items);
            } catch (e) {}
        },
        async fetchSectionListShort({commit}, type = 'dealer') {
            try {
                const {data: {data: {items}}} = await api.catalog.sectionsShort(type);

                commit('SET_SECTION_LIST_SHORT', items);
            } catch (e) {}
        },
        async fetchCategoriesWithProductsList({commit}) {
            try {
                const {data: {data: {items}}} = await api.catalog.categoriesWithProducts();

                commit('SET_CATEGORIES_WITH_PRODUCTS_LIST', items);
            } catch (e) {}
        }
    }
};