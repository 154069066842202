import {get} from "@/lib/axios";

const statistics = {
    async getPointsFlowItems(page = null, pageSize = null, query = '') {
        let url = '/points-flow-items';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async getDealerPointsFlowItemsReport(query = '') {
        let url = '/points-flow-items/report';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {}, {responseType: 'blob'});
    },
    async getSubdivisionStatisticsPointsFlowItemsReport(query = '') {
        let url = '/points-flow-items/subdivision-statistics-report';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {}, {responseType: 'blob'});
    },
};


export {statistics as default}