import { get } from "@/lib/axios";

export default {
  async getVisitOrders(page = null, pageSize = null, query = "") {
    let url = "/agent-organizer/visit-request-orders";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getVisitOrdersStatistics(page = null, pageSize = null, query = "") {
    let url = "/agent-organizer/get-visit-request-orders-statistics";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getVisitOrdersStatisticsReport(query = "") {
    let url = "/agent-organizer/get-visit-request-orders-statistics-report";

    if (query) {
      url += `?${query}`;
    }

    return get(url, {}, { responseType: "blob" });
  },
  async getVisitDocs(id) {
    return get(`/agent-organizer/get-file/${id}`, {}, { responseType: "blob" });
  },
  async getEducationOrdersStatisticsReport(query = "") {
    let url = "/course-order/statistics-report";

    if (query) {
      url += `?${query}`;
    }

    return get(url, {}, { responseType: "blob" });
  },
  async getVisitOrdersManagement(page = null, pageSize = null, query = "") {
    let url = "/agent-organizer/get-visit-request-orders-management";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getEducationOrdersManagement(page = null, pageSize = null, query = "") {
    let url = "/agent-education/get-course-request-orders";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
};
