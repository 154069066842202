import api from '@/api';

export default {
    namespaced: true,
    state: {
        agenciesListShort: []
    },
    getters: {
        productCategoriesShort: ({productCategoriesShort}) => productCategoriesShort,
        sectionList: ({sectionList}) => sectionList,
        agenciesListShort: ({agenciesListShort}) => agenciesListShort
    },
    mutations: {
        SET_PRODUCT_CATEGORIES_SHORT(state, categories) {
            state.productCategoriesShort = categories;
        },
        SET_SECTION_LIST(state, sectionList) {
            state.sectionList = sectionList;
        },
        SET_AGENCIES_LIST_SHORT(state, agenciesShort) {
            state.agenciesListShort = agenciesShort;
        }
    },
    actions: {
        async fetchAgencyListShort({commit}) {
            try {
                const {data: {data: {items}}} = await api.agencies.agenciesShort();
                commit('SET_AGENCIES_LIST_SHORT', items);
            } catch (e) {}
        }
    }
};