import {post, get, put, remove} from '@/lib/axios';

const catalog = {
    async login(credentials) {
        return post('/account/authenticate', credentials);
    },
    async products(type, page = null, pageSize = null, query = '') {
        let url = `/catalog/${type}/products`;

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async createProduct(type, data) {
        return post(`/catalog/${type}/products`, data);
    },
    async updateProduct(id, data) {
        return post(`/catalog/products/${id}`, data);
    },
    async deleteProduct(ids) {
        return remove(`/catalog/products`, { data: { ids } });
    },
    async toggleProductProperty(id, propertyName) {
        return put(`/catalog/products/${id}/toggle/${propertyName}`);
    },
    async copyProductToAnotherCatalog(id, type, data) {
        return put(`/catalog/products/${id}/copy/${type}`, data)
    },
    async moveProductToAnotherCatalog(id, type, data) {
        return put(`/catalog/products/${id}/move/${type}`, data)
    },
    async categories(page = null, pageSize = null, query = '') {
        let url = '/catalog/categories';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },

    async createCategory(data) {
        return post(`/catalog/categories`, data);
    },
    async updateCategory(id, data) {
        return put(`/catalog/categories/${id}`, data);
    },
    async deleteCategory(id) {
        return remove(`/catalog/categories/${id}`);
    },
    async toggleCategoryProperty(id, propertyName) {
        return put(`/catalog/categories/${id}/toggle/${propertyName}`);
    },
    async categoriesShort(type = 'dealer') {
        return get(`/catalog/${type}/categories/short`);
    },
    async categoriesWithProducts() {
        return get('/catalog/categories/with-products');
    },
    async sections(page = null, pageSize = null, query = '') {
        let url = '/catalog/sections';

            if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async createSection(data) {
        return post(`/catalog/sections`, data);
    },
    async updateSection(id, data) {
        return put(`/catalog/sections/${id}`, data);
    },
    async deleteSection(id) {
        return remove(`/catalog/sections/${id}`);
    },
    async sectionsShort(type = 'dealer') {
        return get(`/catalog/${type}/sections/short`);
    },
};

export {catalog as default}
