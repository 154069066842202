import {post, get, put, remove} from '@/lib/axios';

const static_pages = {
    async staticPages(page = null, pageSize = null, query = '') {
        let url = `/static-pages`;

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async staticPage(type) {
        return get(`/static-pages/${type}`);
    },
    async updatePage(id, data) {
        return put(`/static-pages/${id}`, data)
    }
}


export {static_pages as default}