import {get} from "@/lib/axios";

export default {
    async getPointsFlowItemsDealers() {
        return get('/points-flow-items/get-dealers');
    },
    async getPointsFlowItemsSubdivisionUsers() {
        return get('/points-flow-items/get-subdivision-users');
    },
    async getPointsFlowItemsSubdivisionStatistics(page = null, pageSize = null, query = '') {
        let url = '/points-flow-items/subdivision-statistics';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    }

}