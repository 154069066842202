<template>
  <v-app>
  <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/css/reset.css';
</style>

<style lang="sass">
  @import '@assets/sass/main.sass'
</style>

<style lang="css">
  @import 'assets/css/metr/style.bundle.css';
  @import 'assets/css/metr/vendors/base/vendors.bundle.css';
  @import '../node_modules/@mdi/font/css/materialdesignicons.css';
</style>
