import api from "@/api";

export default {
    namespaced: true,
    state: {
        dealersListShort: [],
        areaObjectListShort: [],
    },
    getters: {
        dealersListShort: ({dealersListShort}) => dealersListShort,
        areaObjectListShort: ({areaObjectListShort}) => areaObjectListShort
    },
    mutations: {
        SET_DEALERS_LIST_SHORT(state, dealersShort) {
            state.dealersListShort = dealersShort;
        },
        SET_AREA_OBJECT_LIST_SHORT(state, areaObjectsShort) {
            state.areaObjectListShort = areaObjectsShort;
        }
    },
    actions: {
        async fetchVisitOrderFilter({commit}) {
            try {
                const {data: {data: {dealers, area_objects}}} = await api.orders.getVisitOrderFilters();
                commit('SET_DEALERS_LIST_SHORT', dealers);
                commit('SET_AREA_OBJECT_LIST_SHORT', area_objects);
            } catch (e) {
                console.warn(e);
            }
        }
    }
}