import {get, post, remove} from '@/lib/axios';

export default {
    async getBanner(code) {
        return get(`/banners/${code}`);
    },
    async updateBanner(code, data) {
        return post(`/banners/${code}`, data);
    },
    async deleteBanner(code) {
        return remove(`/banners/${code}`);
    }
}