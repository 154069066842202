import {get, post, put, remove} from '@/lib/axios';

const faq = {
    async getFaq(page = null, pageSize = null, query = '') {
        let url = '/faq';

        if (query) {
            url += `?${query}`;
        }

        return get(url, {page, pageSize});
    },
    async createQuestion(data) {
        return post(`/faq`, data)
    },
    async updateQuestion(id, data) {
        return put(`/faq/${id}`, data)
    },
    async deleteQuestion(id) {
        return remove(`/faq/${id}`);
    },
    async moveQuestion(id, direction) {
        return put(`/faq/${id}/change-order/${direction}`);
    }
}


export {faq as default}