import api from "@/api";

export default {
    namespaced: true,
    state: {
        dealersListShort: [],
        subdivisionsListShort: [],
        subdivisionUsersListShort: []
    },
    getters: {
        dealersListShort: ({dealersListShort}) => dealersListShort,
        subdivisionsListShort: ({subdivisionsListShort}) => subdivisionsListShort,
        subdivisionUsersListShort: ({subdivisionUsersListShort}) => subdivisionUsersListShort,
    },
    mutations: {
        SET_DEALERS_LIST_SHORT(state, dealersShort) {
            state.dealersListShort = dealersShort;
        },
        SET_SUBDIVISIONS_LIST_SHORT(state, subdivisionsShort) {
            state.subdivisionsListShort = subdivisionsShort;
        },
        SET_SUBDIVISION_USERS_LIST_SHORT(state, subdivisionUsersShort) {
            state.subdivisionUsersListShort = subdivisionUsersShort;
        },
    },
    actions: {
        async fetchDealerListShort({commit}) {
            try {
                const {data: {data: {items}}} = await api.agent_user.getDealersForAgentUserProductOrders();
                commit('SET_DEALERS_LIST_SHORT', items);
            } catch (e) {
                console.warn(e);
            }
        },
        async fetchSubdivisionListShort({commit}) {
            try {
                const {data: {data: {items}}} = await api.agent_user.getSubdivisionsForAgentUserProductOrders();
                commit('SET_SUBDIVISIONS_LIST_SHORT', items);
            } catch (e) {
                console.warn(e);
            }
        },
        async fetchSubdivisionUserListShort({commit}) {
            try {
                const {data: {data: {items}}} = await api.agent_user.getSubdivisionUsersForAgentUserProductOrders();
                commit('SET_SUBDIVISION_USERS_LIST_SHORT', items);
            } catch (e) {
                console.warn(e);
            }
        },
    }
}