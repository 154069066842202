import Vue from 'vue'
import Vuex from 'vuex'
import account from '../modules/account/account'
import catalog from '../modules/catalog/catalog'
import agency from '../modules/agency/agency'
import dealer from '../modules/dealer/dealer'
import structuralsubdivisions from '../modules/structuralsubdivision/structuralsubdivision'
import points_flow_items from '../modules/points_flow_items/points_flow_items'
import visit_orders from '../modules/visit_orders'
import orders from "../modules/orders";
import agent_user_orders from '../modules/agent_user_orders'

Vue.use(Vuex);

const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false
};

const mutations = {
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
        state[variable] = value
    }
};

export default new Vuex.Store({
    state,
    mutations,
    modules: {
        account,
        catalog,
        agency,
        dealer,
        structuralsubdivisions,
        points_flow_items,
        visit_orders,
        orders,
        agent_user_orders
    }
})
