import api from "@/api";

export default {
    namespaced: true,
    state: {
        dealersListShort: [],
        subdivisionUsersListShort: []
    },
    getters: {
        dealersListShort: ({dealersListShort}) => dealersListShort,
        subdivisionUsersListShort: ({subdivisionUsersListShort}) => subdivisionUsersListShort
    },
    mutations: {
        SET_DEALERS_LIST_SHORT(state, dealersShort) {
            state.dealersListShort = dealersShort;
        },
        SET_SUBDIVISION_USERS_LIST_SHORT(state, subdivisionUsersShort) {
            state.subdivisionUsersListShort = subdivisionUsersShort;
        }
    },
    actions: {
        async fetchDealerListShort({commit}) {
            try {
                const {data: {data: {items}}} = await api.points_flow_items.getPointsFlowItemsDealers();
                commit('SET_DEALERS_LIST_SHORT', items);
            } catch (e) {
                console.warn(e);
            }
        },
        async fetchSubdivisionUserListShort({commit}) {
            try {
                const {data: {data: {items}}} = await api.points_flow_items.getPointsFlowItemsSubdivisionUsers();
                commit('SET_SUBDIVISION_USERS_LIST_SHORT', items);
            } catch (e) {
                console.warn(e);
            }
        }
    }
}