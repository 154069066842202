import {get, post} from "@/lib/axios";

const request_write_down_points = {
  async getRequestsWriteDownPoints(page = null, pageSize = null, query = "") {
    let url = "/requests/write-down-points";

    if (query) {
      url += `?${query}`;
    }

    return get(url, { page, pageSize });
  },
  async getRequestsWriteDownPointsReport(query = "") {
    let url = "/requests/write-down-points-report";

    if (query) {
      url += `?${query}`;
    }

    return get(url, {}, { responseType: "blob" });
  },
  async updateRequestsWriteDownPointsDescription(id, description) {
    return post(`/requests/write-down-points/description/${id}`, { description })
  },
  async updateRequestsWriteDownPointsStatus(id, status, comment = '') {
    const data = { status }
    if (comment) {
      data.comment = comment
    }
    return post(`/requests/write-down-points/status/${id}`, data)
  },
  async deleteRequestsWriteDownPointsFile(id, fileType){
    return post(`/requests/write-down-points/file/${id}`, { fileType })
  },
  async uploadRequestsWriteDownPointsFiles(data) {
    return post('/requests/write-down-points/files', data)
  }
}

export default request_write_down_points;