import account from  './entities/account';
import catalog from  './entities/catalog';
import dealers from './entities/dealers';
import mailings from './entities/mailings';
import agencies from  './entities/agencies';
import structural_subdivisions from  './entities/structural_subdivisions';
import static_pages from  './entities/static_pages';
import system_params from  './entities/system_params';
import faq from  './entities/faq';
import banner from "./entities/banner";
import orders from "./entities/orders";
import statistics from "./entities/statistics";
import points_flow_items from "./entities/points_flow_items";
import travels from "./entities/travels";
import agent_user from "./entities/agent_user";
import agent_organizer from "./entities/agent_organizer";
import request_write_down_points from "@/api/entities/request_write_down_points";

const api = {
    account,
    catalog,
    dealers,
    mailings,
    agencies,
    structural_subdivisions,
    static_pages,
    system_params,
    faq,
    banner,
    orders,
    statistics,
    points_flow_items,
    agent_user,
    agent_organizer,
    request_write_down_points,
    travels,
};

export {api as default}
